<template>
  <div />
</template>

<script >
import { onMounted } from '@vue/composition-api'; // import axios from 'axios';

const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  },
  containerId: {
    type: String,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;

  const handleElementGatewayEvent = eventResponse => {
    if (eventResponse.type === 'ELEMENTINSURANCE:EXPIRED') {
      console.log('Payment session expired, please initiate a new payment.');
      emit('update-payment-status', 'EXPIRED');
    } else if (eventResponse.type === 'ELEMENTINSURANCE:LOADING_FAILED') {
      console.log('Payment session could not be loaded, error:', eventResponse.data.error);
    } else if (eventResponse.type === 'ELEMENTINSURANCE:FAILURE') {
      console.log('Payment failed, error:', eventResponse.data.error);
      emit('update-payment-status', 'FAILED');
    } else if (eventResponse.type === 'ELEMENTINSURANCE:SUCCESS') {
      console.log('Payment session could not be loaded, error:', eventResponse.data.error);
      emit('update-payment-status', 'SUCCESS');
    }
  }; // const createQuote = async () => {
  //     const { payload, apiKey } = props.field.properties.basic;
  //     const createQuoteEndpoint = (payload.environment === 'PRODUCTION' ? 'https://api.element.in' : 'https://sandbox-api.element.in') + '/quotes';
  //     const apiPayload = {
  //         elements: [
  //             {
  //                 name: payload.name,
  //                 attributes: {
  //                     ...(payload.name === 'car-warranty-one-time-b2c' ? { dealerSurcharge: computeDealerSurcharge() } : {}),
  //                     duration: payload.duration,
  //                     productVariant: payload.productVariant
  //                 }
  //             }
  //         ],
  //         payment: {
  //             frequency: {
  //                 period: 1,
  //                 unit: 'ONE_TIME'
  //             }
  //         },
  //         productId: getProductId()
  //     };
  //     const { data: responseData } = await axios({
  //         method: 'POST',
  //         url: createQuoteEndpoint,
  //         headers: {
  //             Authorization: `Key ${apiKey}`
  //         },
  //         data: apiPayload
  //     });
  //     return responseData;
  // };
  // const getProductId = () => {
  //     const { payload } = props.field.properties.basic;
  //     return payload.name === 'car-warranty-one-time-b2c' ? '57ca71f0-fda7-4ee4-a5b9-1b53f3f8a451' : '00e8bba6-3131-47dc-a12c-2472e907f2ca';
  // };
  // const computeDealerSurcharge = () => {
  //     const { payload } = props.field.properties.basic;
  //     let surcharge = 0;
  //     if (payload.name === 'car-warranty-one-time-b2c') {
  //         if (payload.productVariant === 'M' && payload.duration === 'P1Y') {
  //             surcharge = 2.421;
  //         } else if (payload.productVariant === 'L') {
  //             if (payload.duration === 'P1Y') {
  //                 surcharge = 1.708;
  //             } else if (payload.duration === 'P2Y') {
  //                 surcharge = 2.467;
  //             } else if (payload.duration === 'P3Y') {
  //                 surcharge = 2.679;
  //             }
  //         } else if (payload.productVariant === 'XL') {
  //             if (payload.duration === 'P1Y') {
  //                 surcharge = 0.857;
  //             } else if (payload.duration === 'P2Y') {
  //                 surcharge = 1.6;
  //             } else if (payload.duration === 'P3Y') {
  //                 surcharge = 1.786;
  //             }
  //         } else if (payload.productVariant === 'L2') {
  //             if (payload.duration === 'P1Y') {
  //                 surcharge = 1.097;
  //             } else if (payload.duration === 'P2Y') {
  //                 surcharge = 1.889;
  //             } else if (payload.duration === 'P3Y') {
  //                 surcharge = 2;
  //             }
  //         } else if (payload.productVariant === 'XL2') {
  //             if (payload.duration === 'P1Y') {
  //                 surcharge = 0.444;
  //             } else if (payload.duration === 'P2Y') {
  //                 surcharge = 1.167;
  //             } else if (payload.duration === 'P3Y') {
  //                 surcharge = 1.438;
  //             }
  //         }
  //     }
  //     return surcharge;
  // };


  const initElementGateway = async () => {
    try {
      const {
        environment,
        quoteId,
        signedUrl
      } = props.field.properties.basic.payload; // const qouteDetails = await createQuote();

      const gatewayPayload = {
        containerId: props.containerId,
        quoteId,
        signedUrl,
        environment,
        onEvent: handleElementGatewayEvent
      };
      window.ElementInsurance(gatewayPayload);
    } catch (err) {
      console.error(err);
    }
  };

  onMounted(() => {
    initElementGateway();
  });
  return {};
};

export default __sfc_main;
</script>
